"use client";
import { ApolloError } from "@apollo/client";

import {
  ClientErrors,
  GraphqlErrors,
  NetworkError,
  GeneralError,
} from "@/components/model";

export default function Error({
  error,
  reset,
}: {
  error: ApolloError;
  reset: () => void;
}) {
  if (error.graphQLErrors) {
    return <GraphqlErrors graphQLErrors={error.graphQLErrors} reset={reset} />;
  }
  if (error.clientErrors) {
    return <ClientErrors clientErrors={error.clientErrors} />;
  }
  if (error.networkError) {
    return <NetworkError networkError={error.networkError} />;
  }
  return <GeneralError error={error} />;
}
